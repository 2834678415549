import React, { Component } from "react";
import {Breadcrumb, Grid, Col, Row } from "react-bootstrap";
import { ExportToCsv } from 'export-to-csv';
import ReactTable from "react-table-6";
import Moment from 'react-moment';
import StatsCard from "components/Card/StatsCard.jsx";
import Loader from "components/Loader/loader.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
const Config = require('config');


class Invoices extends Component {




    constructor(props){
        super(props);


        
                this.state = {
                  loading: true,
                paymentsdatalist:[],
                owed:"",
                paid:"",
                kyc:false
                  
                }
               
              //  console.log('this.props', this.props)
            }
    

            
componentWillMount(){

    this.geInvoices();
    var user=JSON.parse(localStorage.getItem('user'));

    if (user.data.kyc=='Verified'){this.setState({kyc:true})}else{
this.setState({kyc:false})}

  }
  
  geInvoices(){

    var user=JSON.parse(localStorage.getItem('user'));
    var mid=user.data.merchant_id;
    var token=user.token;

    const requestOptions = {
      headers: {'access-token': token,'mid':user.data.merchant_id},
   };
      


    fetch(Config.API+'/api/invoices/',requestOptions)
    .then( this.handleResponse)
    .then( paymentdata => this.setState({ paymentsdatalist:paymentdata.data, loading: false}))
   

  }




  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
console.log(data)
          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'

          }
        

        }
       

        return data;
    });
}

  
newinvoice(){ window.location.hash = "#/billing/invoices/new"};

  

  render() {
   
    const {kyc} = this.state; 

    if (this.state.loading) return <Loader />
    return (
         <div className="main-content">
          
        <Grid fluid>
      
          <Row>
          <Col lg={12}>  <h3>Invoices</h3> </Col>
          <Breadcrumb>
            <Breadcrumb.Item href="/#/billing">Billing</Breadcrumb.Item>
            <Breadcrumb.Item active>Invoices</Breadcrumb.Item>
          </Breadcrumb>

<Col lg={12}>

          <div className="card">
        
             
<div className="content">
{kyc==true?<Button  bsStyle="default"  onClick={() => this.newinvoice()}>
                      New
                        <span className="btn-label btn-label-right">
                        <i className="fal fa-file-invoice-dollar" />
                        </span>
                      </Button>
                      :<div></div>}
                      <ReactTable
                  
                  data={this.state.paymentsdatalist}
                  filterable
                  columns={[
                    {
                      Header: "Invoice Date",
                      accessor:"invoice_date",
                      filterable: false,
                      Cell: row => {
                        return <Moment format="MM/D/YYYY">{row.original.invoice_date}</Moment>;
                      },
                    },


                    {
                      Header: "Customer",
                      accessor:"display_name"
                     
                 
                    },

                    {
                      Header: "Currency",
                      accessor:"currency"
                     
                 
                    },
                    {
                      Header: "Amount",
                      accessor:"total_amount",
 
                      Cell: row => <div>{(row.original.total_amount).toFixed(2)}</div>
                    },
                     
                 
                   
                    {
                      Header: "Due Date",
                      accessor:"due_date",
                      filterable: false,
                      Cell: props => {
                        return <Moment format="MM/D/YYYY">{props.value}</Moment>;
                      },
                    },
                    {
                      Header: "Invoice Id",
                      accessor:"ref_id"    },
                      {
                        Header: "Status",
                        accessor:"payment_status",
                        filterable: false,
                      Cell: row => (row.original.status=='Draft'?<div>{row.original.status}</div>:<div>{row.original.payment_status}</div>)
                      },

                      {
                        Header: "Actions",
                        width: 200,
                        sortable: false,
                        filterable: false,
                        Cell: row => (
                          <div className="actions-right">
                               {row.original.status=='Draft'?<Button
                               
     onClick={() => window.location.hash = "#/billing/invoices/"+row.original.invoice_id}
    bsStyle="info"
    simple
    icon
    ><i className="fal fa-edit"></i> Edit Draft</Button>
    :<Button                       
    onClick={() => window.location.hash = "#/billing/invoices/"+row.original.invoice_id}
bsStyle="info"
simple
icon
><i className="far fa-eye"></i> View</Button>}{" "}
                          </div>
  
  
  
                      )}
                    
                    
                  ]}
                  defaultPageSize={10}
                  showPaginationBottom
                  showPaginationBottom={true}
                  className="-striped -highlight"
                  noDataText= "No Invoices Found"
                />
              

          </div>
          </div></Col>
          </Row>
        </Grid>
      </div>









    );
  }
}

export default Invoices;