let Config = {
 API:'https://payapi.swapdigital.com:5000',
  //API:'http://localhost:5000',
  BtcTxUrl:'https://blockexplorer.one/btc/mainnet/tx/',
  BhcTxUrl:'https://blockexplorer.one/bch/mainnet/tx/',
  LtcTxUrl:'https://blockexplorer.one/ltc/mainnet/tx/',
  EthTxUrl:'https://etherscan.io/tx/',
  MinInvoice:25,
  gtag:'AW-00000',
  Company:'Swap Digital',
  Website:'https://swapdigital.com',
  WidgetUrl:'https://swapdigital.com/widget/swap.js?v4',
  WidgetName:'swap',
  VerifyUrl:'https://swapdigital.com/merchant_verify/'
};
module.exports = Config;