import React, { Component } from "react";
import {
  Breadcrumb,  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Loader from "components/Loader/loader.jsx";

import Button from "components/CustomButton/CustomButton.jsx";
const Config = require('config');


class AccountInfo extends Component {
  constructor(props) {
    super(props);

    this.vForm = this.refs.vForm;
    this.state = {
      loading: true,
      alert: null,
      show: false,
      merchant_id:null,
      merchant_name:'',
      merchant_url:'',
      merchant_email:"",
      merchant_phone:"",
      merchant_address:"",
      merchant_city:"",
      merchant_prov_state:"",
      merchant_postal_zip:"",
      merchant_country:"",
      merchant_nameError: null,
      merchant_emailError: null,
      merchant_phoneError: null,
      merchant_addressError: null,
      merchant_cityError: null,
      merchant_prov_stateError: null,
      merchant_postal_zipError: null,
      merchant_countryError: null,
      Page_Error:null,
     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleValidate = this.handleValidate.bind(this);

 
  }

  componentWillMount(){

    this.getmerchantinfo();


  }

  getmerchantinfo(){
    var user=JSON.parse(localStorage.getItem('user'));
   
    const requestOptions = {
      headers: { 'access-token': user.token,'mid':user.data.merchant_id },
    };

    fetch(Config.API+'/api/merchant/',requestOptions)
    .then( this.handleResponse)
      .then( merchant => this.setState({merchant_name:merchant.data.name,merchant_url:merchant.data.url,merchant_email:merchant.data.email,merchant_phone:merchant.data.phone,merchant_city:merchant.data.city,merchant_country:merchant.data.country,merchant_postal_zip:merchant.data.postal_zip,merchant_phone:merchant.data.phone,merchant_prov_state:merchant.data.prov_state,merchant_address:merchant.data.address,merchant_id:user.data.merchant_id, loading: false}));

     
  
    }


    updatemerchantinfo(name,address,city,prov_state,postal_zip,email, country,phone,url){
      var user=JSON.parse(localStorage.getItem('user'));
      var mid=user.data.merchant_id;
var token=user.token;
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'access-token': token,'mid':user.data.merchant_id},
         body: JSON.stringify({ name,address,city,prov_state,postal_zip,email,country,phone,url})
      };
          
    
  return fetch(Config.API+`/api/merchant/update`,requestOptions)
     .then( this.handleResponse)
     .then(this.props.handleClick("tr","success","Account info has been updated",<span data-notify='icon' className='pe-7s-check' />))
    
    
  }


  handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
 
        if (!response.ok) {
            
         // const error = (data && data.message);
          return 'error'
        }else{
        //  console.log(data.status)
          if(data.status=='error'){
            localStorage.removeItem('user');
            window.location.reload(true);
               return 'error'
          }
        

        }
       

        return data;
    });
}


    handleChange(e) {
      const { name, value } = e.target;
      this.setState({ [name]: value });
   
  }



  
  handleValidate(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    re.test(this.state.merchant_email) === false
      ? this.setState({
        merchant_emailError: (
            <small className="text-danger">
              This must be a valid email address.
            </small>
          )
        })
      : this.setState({ merchant_emailError: null });
    
      this.state.merchant_address === ""
      ? this.setState({
        merchant_addressError: (
            <small className="text-danger">field is  required.</small>
          )
            })
      : this.setState({ merchant_addressError: null });

        this.state.merchant_phone=== ""
        ? this.setState({
          merchant_phoneError: (
              <small className="text-danger">a valid phone number is required.</small>
            )
              })
        : this.setState({ merchant_phoneError: null });

      this.state.merchant_name === ""
      ? this.setState({
        merchant_nameError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_nameError: null });
     
      this.state.merchant_prov_state === ""
      ? this.setState({
        merchant_prov_stateError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_prov_stateError: null });
      
  
      this.state.merchant_postal_zip === ""
      ? this.setState({
        merchant_postal_zipError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_postal_zipError: null });
    
      this.state.merchant_country === ""
      ? this.setState({
        merchant_countryError: (
            <small className="text-danger">field is  required.</small>
          )
           })
      : this.setState({ merchant_countryError: null });
    
      this.state.merchant_city === ""
      ? this.setState({
        merchant_cityError: (
            <small className="text-danger">field is  required.</small>
          )
          })
      : this.setState({ merchant_cityError: null });
  
  }

  handleSubmit(e) {
    e.preventDefault();
    const { merchant_name,merchant_address,merchant_city,merchant_prov_state,merchant_postal_zip,merchant_email,merchant_country,merchant_phone,merchant_url} = this.state;
   if( this.state.merchant_cityError === null && this.state.merchant_nameError  === null && this.state.merchant_addressError  === null && this.state.merchant_prov_stateError  === null && this.state.merchant_postal_zipError  === null && this.state.merchant_countryError  === null && this.state.merchant_emailError  === null && this.state.merchant_phoneError  === null){
  this.updatemerchantinfo(merchant_name,merchant_address,merchant_city,merchant_prov_state,merchant_postal_zip,merchant_email,merchant_country,merchant_phone,merchant_url)}

   }




  render() {
    const { merchant_name,merchant_email,merchant_address,merchant_city,merchant_prov_state,merchant_postal_zip, merchant_country, merchant_phone, merchant_id,loading,merchant_url} = this.state;
    if (loading) return <Loader />
     return (
      <div className="main-content">


 <Grid fluid>
   
          <Row>
         
          <Col lg={12}>  <h3>Account Info</h3> </Col>     <Breadcrumb>
            <Breadcrumb.Item href="/#/settings">Settings</Breadcrumb.Item>
            <Breadcrumb.Item active>Account Info</Breadcrumb.Item>
          </Breadcrumb>
                    <Col md={12}> 
              <Form horizontal onSubmit={this.handleSubmit}>
                <Card
            
                  content={
                    <div>
 <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Merchant ID
                        </Col>
                        <Col sm={6}>
                       
                           <div  className="textdisplay">{merchant_id}</div>
                        </Col>
                      </FormGroup>





                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                         Name
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_name"
                          value={merchant_name}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                           {this.state.merchant_nameError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Address
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_address"
                          value={merchant_address}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_addressError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        City
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_city"
                          value={merchant_city}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_cityError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Province/State
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_prov_state"
                          value={merchant_prov_state}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_prov_stateError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                     Country
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_country"
                          value={merchant_country}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_countryError}
                        </Col>
                      </FormGroup>


                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                        Postal/Zip Code
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_postal_zip"
                          value={merchant_postal_zip}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_postal_zipError}
                        </Col>
                      </FormGroup>



                      <FormGroup controlId="formHorizontalRequiredText">
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                       Phone Number
                        </Col>
                        <Col sm={6}>
                          <FormControl
                            type="text"
                            name="merchant_phone"
                          value={merchant_phone}
                            onBlur={this.handleValidate}
                            onChange={this.handleChange}
                          />
                          {this.state.merchant_phoneError}
                        </Col>
                      </FormGroup>




                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                          Email
                        </Col>
                        <Col sm={6}> 
                          <FormControl
                            type="email"
                            value={merchant_email}
                            name="merchant_email"
                         onBlur={this.handleValidate}
                         onChange={this.handleChange}
                          />
             { this.state.merchant_emailError}
                        </Col>
                      </FormGroup>

                      <FormGroup controlId="formHorizontalEmail"> 
                        <Col componentClass={ControlLabel} sm={2} smOffset={2}>
                          Website Url
                        </Col>
                        <Col sm={6}> 
                          <FormControl
                            type="text"
                            value={merchant_url}
                            name="merchant_url"
                         onBlur={this.handleValidate}
                         onChange={this.handleChange}
                          />
            
                        </Col>
                      </FormGroup>
                         
                   
                    
                    </div>
                  }
      
                  legend={
                    <Button
                      fill
                      bsStyle="info"
                      type="submit"
                      onClick={this.handleValidate}
                    >
                      Save
                    </Button>
                  }
                />
              </Form>
            </Col>
                 
                  </Row>
               
        </Grid>
      </div>
    );
  }
}

export default AccountInfo ;
